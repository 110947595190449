<template>
<div
  ref="scrollchart"
>
  <v-card>
    <v-card-title>
      {{ charts.title }}
    </v-card-title>
    <v-card-text>
      <v-form>
        <v-radio-group
          v-model="selected"
        >
          <v-radio
            v-for="(chart,index) in charts.items"
            :key="chart.id+nextid+index"
            :label="chart.answer"
            :value="index"
          >
          </v-radio>
        </v-radio-group>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-btn
        @click="nextChart()"
      >
        次へ
      </v-btn>
    </v-card-actions>
  </v-card>
  <ChildComp
    v-if="nexted"
    :next="nextcharts"
  />
</div>
</template>

<script>
import ChildComp from './child.vue'
// import goTo from 'vuetify/lib/services/goto'

export default {
  name:'ChildComp',
  components:{
    ChildComp,
  },
  props:[ 'next' ],
  data(){
    return {
      charts: this.next,
      selected:"",
      nexted:false,
      nextcharts:"",
      nextChartSearch:[
        {
          id:null,
        },
        {
          id:0,
          name:'finishtrue'
        },
        {
          id:1,
          name:'finishfalse'
        },
        {
          id:2,
          name:'chartsToGrand',
          next:this.$store.state.grand
        },
        {
          id:3,
          name:'chartsToSpouse',
          next:this.$store.state.spouse
        },
        {
          id:4,
          name:'chartsToSpouseGrand',
          next:this.$store.state.spousegrand
        },
        {
          id:5,
          name:'chartsGrandAtSpouse',
          next:this.$store.state.grandatspouse
        },
      ]
    }
  },
  methods:{
    nextChart(){
      console.log('nextChart')
      const nextchart = this.nextChartSearch.find((value) => value.id === this.next.items[this.selected].selectedFinish)
      console.log('nextchart',nextchart);
      if(nextchart.id === null){
        console.log('次の設問へ')
        this.nextcharts = this.next.items[this.selected].next;
        this.nexted = !this.nexted;
        this.$vuetify.goTo(this.target,this.options);
      } else if(nextchart.id < 2){
        console.log('結果画面へ移動')
        this.$router.push({
          name:nextchart.name
        })
      } else {
        console.log(nextchart.name+'のチャートへ移動')
        this.nextcharts = nextchart.next;
        this.nexted = !this.nexted;
        this.$vuetify.goTo(this.target,this.options);
      }
    },
  },
  computed:{
    nextid(){
      const uuid = Math.random()+new Date().getTime()
      return  uuid.toString(16);
    },
    target(){
      const value = this['element'];
      return value;
    },
    option(){
      return {
        duration:700,
        offset: 0,
        easing: 0,
      }
    },
    element(){
      return this.$refs.scrollchart;
    }
  },
  updated(){
    console.log('selected',this.selected)
  },
  created(){
    console.log('this.next',this.next);
    console.log('charts',this.charts);
  }
}
</script>