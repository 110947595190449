import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    next:{
      title:"",
      color:"",
      index:0,
      add:false,
      finish:false,
      items:[{
        id:0,
        answer:"",
        selectedFinish:null,
        finishDisabled:false,
        next:{},
      }],
      resetkey:0,
    },
    grand:{
      title:"",
      color:"",
      index:0,
      add:false,
      finish:false,
      items:[{
        id:0,
        answer:"",
        selectedFinish:null,
        finishDisabled:false,
        next:{},
      }],
      resetkey:0,
    },
    spouse:{
      title:"",
      color:"",
      index:0,
      add:false,
      finish:false,
      items:[{
        id:0,
        answer:"",
        selectedFinish:null,
        finishDisabled:false,
        next:{},
      }],
      resetkey:0,
    },
    grandatspouse:{
      title:"",
      color:"",
      index:0,
      add:false,
      finish:false,
      items:[{
        id:0,
        answer:"",
        selectedFinish:null,
        finishDisabled:false,
        next:{},
      }],
      resetkey:0,
    },
    spousegrand:{
      title:"",
      color:"",
      index:0,
      add:false,
      finish:false,
      items:[{
        id:0,
        answer:"",
        selectedFinish:null,
        finishDisabled:false,
        next:{},
      }],
      resetkey:0,
    },
  },
  mutations: {
    setChartData(state,setdata){
      console.log('vuex書き込み')
      if(setdata.chartdata.timestamp.seconds){
        state[setdata.selected] = setdata.chartdata;
        state[setdata.selected].resetkey = setdata.chartdata.timestamp.seconds;
        console.log('state.next',state[setdata.selected])
      } else if(!setdata.chartdata.timestamp.seconds){
        return
      }
    }
  },
  actions: {
    setChartData({ commit }, setdata){
      commit('setChartData',setdata);
    }
  },
  modules: {
  }
})
