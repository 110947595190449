<template>
  <v-container>
    <v-row>
      <v-col>
        ホーム
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <!-- <v-select
          v-model="itemSelected"
          :items="items"
          item-text="text"
          item-value="value"
          class="inline"
          @change="nowloading"
        /> -->
        <v-btn
          :disabled="loading"
          @click="editpush"
        >
          チャート編集へ
        </v-btn>
      </v-col>
      <v-col>
        <v-btn
          @click="chartpush"
        >
          簡易チャートへ
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { initializeApp } from 'firebase/app';
import { getFirestore, collection,getDocs,query,orderBy,limit,} from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCz-BCQSzk0HH0u2hCQFOUTjUSCVRfknsg",
  authDomain: "kenyaku-support.firebaseapp.com",
  projectId: "kenyaku-support",
  storageBucket: "kenyaku-support.appspot.com",
  messagingSenderId: "751777233332",
  appId: "1:751777233332:web:e25c9a804ac8d6732bdae1"
};
const app = initializeApp(firebaseConfig);

export default {
  data(){
    return{
      itemSelected:'next',
      items:[
        {
          text:'自身',
          value:'next'
        },
        {
          text:'自身の祖父母',
          value:'grand'
        },
        {
          text:'配偶者',
          value:'spouse'
        },
        {
          text:'自身の祖父母(配偶者有りの場合)',
          value:'grandatspouse'
        },
        {
          text:'配偶者の祖父母',
          value:'spousegrand'
        },
      ],
      loading:false,
    }
  },
  methods:{
    async editpush(){
      await this.load();
      console.log(this.$store.state[this.itemSelected])
      this.$router.push({
        name:'chartedithome',
        params:{
          chart:this.itemSelected
        }
      })
    },
    chartpush(){
      this.$router.push({
        name:'mainchart',
      })
    },
    nowloading(){
      this.loading = true;
      this.load();
    },
    async load(){
      const dlcharts = await collection(getFirestore(app),this.itemSelected);
      const charts = await query(dlcharts,orderBy("timestamp","desc"), limit(1));
      let chartdata = "";
      getDocs(charts).then((doc) => {
        console.log('doc',doc);
        doc.forEach(doc => {
          chartdata = doc.data();
        })
        console.log('chartdata',chartdata)
        // this.chartdatas = chartdata;
        const setdata = {
          chartdata,
          selected:this.itemSelected
        }
      this.$store.dispatch('setChartData',setdata);
      this.loading = false;
      })
      console.log('読込が完了しました')
    }
  },
  created(){
    this.load();
  }
}
</script>