<template>
  <v-main
    max-height="800"
  >
    <v-btn
      @click="save"
    >
      保存する
    </v-btn>
    <v-btn
      @click="load"
    >
      読み込む
    </v-btn>
    <v-select
      v-model="itemSelected"
      :items="items"
      item-text="text"
      item-value="value"
      class="inline"
    />
    <v-btn
      @click="chartpush"
    >
      実際のチャートへ
    </v-btn>
    <v-btn
      @click="backhome"
    >
      ホームへ
    </v-btn>
    <Charts
      @server-save="stateSaving"
      :charts="chartdatas"
      :key="resetkey"
     />
  </v-main>
</template>

<script>
import Charts from '@/components/charts.vue'
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, addDoc,getDocs,query,orderBy,limit,serverTimestamp } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCz-BCQSzk0HH0u2hCQFOUTjUSCVRfknsg",
  authDomain: "kenyaku-support.firebaseapp.com",
  projectId: "kenyaku-support",
  storageBucket: "kenyaku-support.appspot.com",
  messagingSenderId: "751777233332",
  appId: "1:751777233332:web:e25c9a804ac8d6732bdae1"
};
const app = initializeApp(firebaseConfig);

export default {
  name: 'ChartEditHome',
  components: {
    Charts
  },
  data(){
    return{
      chartdatas:{},
      itemSelected:'next',
      items:[
        {
          text:'自身',
          value:'next'
        },
        {
          text:'自身の祖父母',
          value:'grand'
        },
        {
          text:'配偶者',
          value:'spouse'
        },
        {
          text:'自身の祖父母(配偶者有りの場合)',
          value:'grandatspouse'
        },
        {
          text:'配偶者の祖父母',
          value:'spousegrand'
        },
      ]
    }
  },
  computed:{
    resetkey(){
      return this.$store.state[this.itemSelected].resetkey;
    }
  },
  methods:{
    async save(){
      console.log('this.itemSelected',this.itemSelected)
      console.log('this.$store.state[this.itemSelected]',this.$store.state[this.itemSelected]);
      this.$store.state[this.itemSelected].timestamp = serverTimestamp();
      console.log('this.$store.state.next',this.$store.state[this.itemSelected])
      await addDoc(collection(getFirestore(app),this.itemSelected),this.$store.state[this.itemSelected]);
      alert('保存しました')
      console.log('保存後　this.$store.state[this.itemSelected]',this.$store.state[this.itemSelected]);
    },
    async load(){
      console.log('this.itemSelected',this.itemSelected)
      const dlcharts = await collection(getFirestore(app),this.itemSelected);
      const charts = await query(dlcharts,orderBy("timestamp","desc"), limit(1));
      let chartdata = "";
      getDocs(charts).then((doc) => {
        console.log('doc',doc);
        doc.forEach(doc => {
          chartdata = doc.data();
        })
        console.log('chartdata',chartdata)
        this.chartdatas = chartdata;
        const setdata = {
          chartdata,
          selected:this.itemSelected
        }
      this.$store.dispatch('setChartData',setdata);

      alert('読込しました');
      });
    },
    stateSaving(chart){
      console.log('chart',chart);
      this.$store.state[this.itemSelected] = chart;
      console.log('変更結果',this.$store.state[this.itemSelected])
    },
    chartpush(){
      this.$router.push({
        name:'mainchart'
      })
    },
    backhome(){
      this.$router.push({
        name:'home'
      })
    }
  },
  created(){
    // this.chartdata = this.load();
    console.log(this.itemSelected)
    console.log('ChartEditHome Created',this.$store.state[this.itemSelected]);
  },
  updated(){
    console.log('updated',this.$store.state[this.itemSelected])
  }
}
</script>
