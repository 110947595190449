<template>
  <v-list
    class="overflow-auto"
    :rippel="false"
  >
    <v-list-group
      :value="true"
      no-action
      sub-group
      :rippel="false"
    >
      <template v-slot:activator>
        <v-list-item-content>
          <v-list-item-title>
            <v-text-field
              v-model="charts.title"
              placeholder="設問"
              class="red lighten-4"
            />
          </v-list-item-title>
        </v-list-item-content>
      </template>
      <v-list-item
        v-for="(item,index) in charts.items"
        :key="item.id+nextid+index"
        dense
        :rippel="false"
        class="wrap-text"
      >
      <v-form>
        <v-container
        fluid
      >
      <v-row>
        <v-col
          cols="12"
        >
          <v-text-field
            v-model="item.answer"
            placeholder="回答"
          />
        </v-col>
        <v-col
          cols="12"
        >
          <v-btn
          @click="nextchart(item,index)"
          :disabled="item.finishDisabled"
        >
          次の設問を追加する
        </v-btn>
        <v-btn
          @click="emitPopNext(item.backindex)"
          :disabled="item.finishDisabled"
        >
          設問を減らす
        </v-btn>
        <v-btn
          @click="add(index)"
          icon
        >
          <v-icon>
            mdi-plus-circle
          </v-icon>
        </v-btn>
        <v-btn
          @click="sub(index)"
          icon
        >
          <v-icon>
            mdi-minus-circle
          </v-icon>
        </v-btn>
          <v-select
            v-model="item.selectedFinish"
            :items="items"
            item-text="text"
            item-value="value"
            :disabled="item.finishDisabled"
          />
          <v-btn
            @click="finishChart(index)"
            icon
          >
            <v-icon>
              mdi-check
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
      </v-container>
      </v-form>
        <ChartComp
          v-if="add"
          :charts="item.next"
          :backindex="item.index"
          @pop-next="PopNext"
        />
      </v-list-item>
    </v-list-group>
  </v-list>
</template>

<script>
import ChartComp from './charts.vue'

export default {
  name:"ChartComp",
  props:[ 'charts','chartdata','backindex' ],
  components:{
    ChartComp,
  },
  data(){
    return {
      backgroundcolor:"",
      open:true,
      items:[
        {
          text:'可能性有りで終わる',
          value:0,
        },
        {
          text:'可能性無しで終わる',
          value:1,
        },
        {
          text:'祖父母へ',
          value:2,
        },
        {
          text:'配偶者へ',
          value:3,
        },
        {
          text:'配偶者の祖父母へ',
          value:4,
        },
        {
          text:'自身の祖父母へ（配偶者有りの場合）',
          value:5,
        },
      ]
    }
  },
  methods:{
    add(index){
      console.log('this.charts',this.charts);
      if(!this.backindex){
        this.backindex = index;
      }
      this.charts.items.push({
        id: this.nextid,
        color:this.backgroundcolor,
        // index:this.backindex,
        answer:"",
        selectedFinish:null,
        finishDisabled:false,
        next:{},
      })
      console.log('charts.items',this.charts.items);
    },
    sub(){
      console.log('this.charts',this.charts);
      if(this.charts.items.length > 1){
        this.charts.items.pop();
      }
      console.log('charts.items',this.charts.items);
    },
    save(){
      // const charts = {
      //   title:this.title,
      //   items:this.items,
      // }
      this.$store.state.charts = this.charts.items;
      this.$emit('server-save',this.charts);
      console.log('this.$store.state.charts',this.$store.state.charts)
    },
    nextchart(item,index){
      console.log('item',item)
      console.log('index',index)
      if(!this.backindex){
        this.backindex = index;
      }
      this.charts.add = true;
      item.next={
        title:"",
        color:this.backgroundcolor,
        index:this.backindex,
        add:false,
        finishDisabled:false,
        items:[{
          id:this.nextid,
          answer:"",
          selectedFinish:null,
          finishDisabled:false,
          next:{},
        }],
      }
      // this.charts.items[index].next = {
      //   title:"",
      //   color:this.backgroundcolor,
      //   index:this.backindex,
      //   items:[{
      //     id:this.nextid,
      //     answer:"",
      //     next:{},
      //   }],
      // }
      console.log('this.charts',this.charts)
      // this.$emit('server-save',this.charts)
    },
    emitPopNext(index){
      this.$emit("pop-next",index);
    },
    PopNext(index){
      console.log('PopNext',index);
      console.log('this.charts.items',this.charts.items)
      if(this.charts.items.length > 1 ){
        this.charts.items.splice(index,1);
      }
      console.log('PopNext charts',this.$store.state.next)
    },
    backgroundColors(color){
      switch (color) {
        case "":
          this.backgroundcolor = "lime lighten-5";
          break;
        case "lime lighten-5":
          this.backgroundcolor = "lime lighten-4";
          break;
        case "lime lighten-4":
          this.backgroundcolor = "lime lighten-3";
          break;
        case "lime lighten-3":
          this.backgroundcolor = "lime lighten-2";
          break;
        case "lime lighten-2":
          this.backgroundcolor = "lime lighten-1";
          break;
        case "lime lighten-1":
          this.backgroundcolor = "lime";
          break;
        case "lime":
          this.backgroundcolor = "lime lighten+1";
          break;
        case "lime lighten+1":
          this.backgroundcolor = "lime lighten+2";
          break;
        case "lime lighten+2":
          this.backgroundcolor = "lime lighten+3";
          break;
        case "lime lighten+3":
          this.backgroundcolor = "lime lighten+4";
          break;
      }
    },
    finishChart(index){
      console.log('this.charts.items',this.charts.items)
      this.charts.items[index].finishDisabled = !this.charts.items[index].finishDisabled;
      if(this.selectedFinish){
        this.charts.finish = true
      } else {
        this.charts.finish = false
      }
      console.log('finishDisabled',this.charts.items[index].finishDisabled);
    },
  },
  computed:{
    nextid(){
      const uuid = Math.random()+new Date().getTime()
      return  uuid.toString(16);
    }
  },
  created(){
    console.log('created');
    if(!this.charts){
      console.log('!this.charts')
      this.charts = this.$store.state.next;
      this.backgroundcolor = this.charts.color;
    }
    console.log('this.charts',this.charts);
    console.log('charts',this.$store.state.next)
    console.log('nextid',this.nextid)
    this.backgroundColors(this.charts.color);
    console.log('color',this.backgroundcolor);
  },
  updated(){
    console.log('updated');
    this.$emit('server-save',this.charts);
  }
}
</script>

<style>
.wrap-text {
  word-break: break-all;
  white-space: normal;
}
</style>