import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import FinishTrue from '../views/FinishTrue.vue'
import FinishFalse from '../views/FinishFalse.vue'
import ChartEditHome from '../views/ChartEditHome.vue'
import mainChart from '../views/mainChart.vue'
// import chartsToGrand from '../views/chartsToGrand.vue'
// import chartsToSpouse from '../views/chartsToSpouse.vue'
// import chartsToSpouseGrand from '../views/chartsToSpouseGrand.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/finishtrue',
    name: 'finishtrue',
    component: FinishTrue
  },
  {
    path: '/finishfalse',
    name: 'finishfalse',
    component: FinishFalse
  },
  {
    path: '/mainchart/:chart',
    name: 'mainchart',
    component: mainChart,
  },
  {
    path: '/chartedithome',
    name: 'chartedithome',
    component: ChartEditHome
  },
  // {
  //   path: '/chartstogrand',
  //   name: 'chartstogrand',
  //   component: chartsToGrand
  // },
  // {
  //   path: '/chartstospouse',
  //   name: 'chartstospouse',
  //   component: chartsToSpouse
  // },
  // {
  //   path: '/chartstospousegrand',
  //   name: 'chartstospousegrand',
  //   component: chartsToSpouseGrand
  // },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
