<template>
  <ChildComp
    :next="charts"
  />
</template>

<script>
import ChildComp from '../components/child.vue'
import { initializeApp } from 'firebase/app';
import { getFirestore, collection,getDocs,query,orderBy,limit,} from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCz-BCQSzk0HH0u2hCQFOUTjUSCVRfknsg",
  authDomain: "kenyaku-support.firebaseapp.com",
  projectId: "kenyaku-support",
  storageBucket: "kenyaku-support.appspot.com",
  messagingSenderId: "751777233332",
  appId: "1:751777233332:web:e25c9a804ac8d6732bdae1"
};
const app = initializeApp(firebaseConfig);

export default {
  name:'mainChart',
  components:{
    ChildComp,
  },
  data(){
    return{
      charts:{},
      items:[
        'grand',
        'spouse',
        'grandatspouse',
        'spousegrand'
      ]
    }
  },
  methods:{
    async load(selected){
      const dlcharts = await collection(getFirestore(app),selected);
      const Charts = await query(dlcharts,orderBy("timestamp","desc"), limit(1));
      let chartdata = "";
      getDocs(Charts).then((doc) => {
        console.log('doc',doc);
        doc.forEach(doc => {
          chartdata = doc.data();
        })
        console.log('chartdata',chartdata)
        // this.chartdatas = chartdata;
        const setdata = {
          chartdata,
          selected:selected
        }
      this.$store.dispatch('setChartData',setdata);
      this.loading = false;
      })
      console.log('読込が完了しました')
    }
  },
  created(){
    console.log('mainChart $store.state.next',this.$store.state.next)
    this.charts = this.$store.state.next;
  },
  mounted(){
    this.items.forEach(value => {
      this.load(value)
    })
  }
}
</script>